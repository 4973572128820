<template>
  <CRow class="justify-content-center">
    <CCol id="shopNoShopsMessage" v-if="shops.length === 0" md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="12" lg="12">
              <a id="shopNoShopsImageLink"
                href="https://apps.apple.com/th/app/silom-pos-point-of-sale/id1230301725?l=th"
              >
                <img id="shopNoShopsImage" src="/img/shop.jpg" class="img-fluid" />
              </a>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol id="shopList" v-else class="col-md-12">
      <div v-for="shop in shops" :key="shop.objectId" :id="'shopCard_' + shop.objectId">
        <CCard class="shadow-sm" @click="selectShop(shop.objectId)">
          <CCardBody style="padding: 2rem 2rem">
            <CRow>
              <CCol
                lg="4"
                class="text-center"
                style="border-right: 1px solid rgb(210, 210, 210);"
              >
                <CRow class="justify-content-center">
                  <CCol lg="12">
                    <h2 id="shopName" class="font-weight-normal">{{ shop.shopName }}</h2>
                  </CCol>
                  <div class="col-lg-7">
                    <div
                      v-if="shop.remoteImagePath != null"
                      class="text-center"
                      style="text-align: center;border-radius: 0.2rem;"
                    >
                      <div class="profile-img doc-img text-center">
                        <div class="profile-widget text-center">
                          <img
                            id="shopProfileImage"
                            style="width:70%"
                            :src="shop.remoteImagePath"
                            class="img-fluid text-center"
                            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/273/273177.png';"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CRow>
                <h4 id="shopBranchName" class="text-success font-weight-normal mt-5">
                  {{ shop.branchName }}
                </h4>
              </CCol>
              <CCol lg="8" style="padding: 0px 40px;">
                <table id="shopDetailsTable" style="width: 100%">
                  <tr>
                    <td>
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('businessType') }}
                      </h5>
                    </td>
                    <td id="shopBusinessType1" v-if="shop.businessType === 0" class="text-right">
                      <h5>
                        {{ $t('businessType1') }}
                      </h5>
                    </td>
                    <td id="shopBusinessType2" v-if="shop.businessType === 1" class="text-right">
                      <h5>
                        {{ $t('businessType2') }}
                      </h5>
                    </td>
                    <td  id="shopBusinessType3" v-if="shop.businessType === 2" class="text-right">
                      <h5>
                        {{ $t('businessType3') }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td id="shopTaxIdValue" style="width: 40%">
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('taxID') }}
                      </h5>
                    </td>
                    <td>
                      <h5 class="text-right text-success">
                        {{ shop.taxId }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('address') }}
                      </h5>
                    </td>
                    <td id="shopAddressValue">
                      <h5 class="text-right">
                        {{ shop.address1 }} {{ shop.address2 }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('tel') }}
                      </h5>
                    </td>
                    <td id="shopTelValue" class="text-right">
                      <h5>
                        {{ shop.tel }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('open') }}
                      </h5>
                    </td>
                    <td id="shopOpenCloseValue" class="text-right">
                      <h5>{{ shop.open }} - {{ shop.close }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('currentPlan') }}
                      </h5>
                    </td>
                    <td id="shopCurrentPlanValue" class="text-right">
                      <h5>{{ currentPlan(shop.currentPlan) }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="text-left font-weight-normal mt-1">
                        {{ $t('endPackate') }}
                      </h5>
                    </td>
                    <td id="shopEndPackageValue" class="text-right">
                      <h5>
                        {{ expire(shop.expire) }}
                      </h5>
                    </td>
                  </tr>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters(['shops']),
  },
  methods: {
    selectShop(shopObjectId) {
      this.$store.dispatch('setShop', shopObjectId)
      this.$store.dispatch('getPermission')
    },
    expire(datetime) {
      if (datetime) {
        return moment(String(datetime)).format('DD MMMM YYYY')
      } else {
        return '-'
      }
    },
    currentPlan(cplan) {
      switch (cplan) {
        case 'multiplePOS':
          return 'SMART'
        case 'starter':
          return 'STARTER'
        case 'standard':
          return 'STANDARD'
        case 'beta' :
          return 'BETA'
        default:
          return '-'
      }
    },
  },
}
</script>
<style src="../../assets/styles/imgProduct.css"></style>
